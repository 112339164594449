import axios from "axios";
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import auth from "../pages/services/token";
import Calendar from "./components/Calendar";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Navigation from "./components/Navigation";
import TraitOrange from "./components/TraitOrange";
// import ButtonEdit from "./components/buttons/ButtonEdit";
const Home = () => {
  const navigate = useNavigate();
  const [meals, setMeals] = useState([]);
  const [filteredMeals, setFilteredMeals] = useState([]);
  const [breakfastMeals, setBreakfastMeals] = useState([]);
  const [lunchMeals, setLunchMeals] = useState([]);
  const [dinnerMeals, setDinnerMeals] = useState([]);
  const [snackMeals, setSnackMeals] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sports, setSports] = useState([]);
  const [selectedSport, setSelectedSport] = useState("");
  const [duration, setDuration] = useState("");
  const [calculatedResult, setCalculatedResult] = useState(0);
  const [userActivity, setUserActivity] = useState([]);
  const [filteredUserActivity, setFilteredUserActivity] = useState([]);
  const [totalCaloriesBurned, setTotalCaloriesBurned] = useState(0);
  const [caloriesPerDay, setCaloriesPerDay] = useState(0);
  const [totalCaloriesRemaining, setTotalCaloriesRemaining] = useState(0);
  const [totalCaloriesConsumed, setTotalCaloriesConsumed] = useState(0);
  //refresh page to view informations userActivity
  const [refreshSport, setRefreshSport] = useState(false);

  ///////////////////////////////////////////////////////////// AFFICHAGE CALORIES   ///////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = auth.getDecodedToken().sub;
        const response = await axios.get(
          `https://caloriesc.com/api/users/${userId}`
        );

        setCaloriesPerDay(response.data.user.caloriesPerDay);
      } catch {}
    };

    fetchUserData();
  }, []);
  ///////////////////////////////////////////////////////////////     MEALS  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const fetchMeals = async () => {
      try {
        //Je vérifie si l'utilisateur est connecté
        if (!auth.isLoggedIn()) {
          navigate("/login", { replace: true });
          return;
        }
        // Je récupere le token JWT de l'utilisateur connecté
        const token = auth.getToken();
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const mealsResponse = await axios.get(
          `https://caloriesc.com/api/meals`,
          config
        );

        const mealsData = mealsResponse.data;

        // Récupérer les informations sur les aliments associés à chaque repas
        const foodIds = mealsData.map((meal) => meal.food_id);
        const foodsResponse = await axios.get(
          `https://caloriesc.com/api/foods?ids=${foodIds.join(",")}`
        );

        const foodsData = foodsResponse.data;

        // Créer un objet indexé par l'ID de l'aliment pour faciliter l'accès
        const foodsById = {};
        foodsData.forEach((food) => {
          foodsById[food.id] = food;
        });

        // Mettre à jour les repas avec les informations sur les aliments
        const mealsWithFoodInfo = mealsData.map((meal) => ({
          ...meal,
          food: foodsById[meal.food_id] || null, // Assigner les informations sur l'aliment, ou null si non trouvé
        }));

        setMeals(mealsWithFoodInfo);
      } catch (error) {}
    };

    fetchMeals();
  }, []);

  useEffect(() => {
    // Filtrer les repas par type et les stocker dans les états appropriés
    const breakfast = filteredMeals.filter(
      (meal) => meal.typeMeals === "breakfast"
    );

    const lunch = filteredMeals.filter((meal) => meal.typeMeals === "lunch");
    const dinner = filteredMeals.filter((meal) => meal.typeMeals === "dinner");
    const snack = filteredMeals.filter((meal) => meal.typeMeals === "snack");

    setBreakfastMeals(breakfast);
    setLunchMeals(lunch);
    setDinnerMeals(dinner);
    setSnackMeals(snack);
  }, [filteredMeals]);

  // Filtrer les repas en fonction de la date sélectionnée
  useEffect(() => {
    const filtered = meals.filter((meal) => {
      const mealDate = new Date(meal.mealDate);
      return mealDate.toDateString() === selectedDate.toDateString();
    });
    setFilteredMeals(filtered);
  }, [meals, selectedDate]);

  // Fonction de gestion du changement de date
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleDeleteMeal = async (mealId) => {
    try {
      await axios.delete(`https://caloriesc.com/api/meals/${mealId}`);
      // Supprimer le repas de l'état local
      setMeals(meals.filter((meal) => meal.id !== mealId));
    } catch (error) {}
  };
  ////////////////////////////////////////////// Total calorie Meals /////////////////////////////////////////////

  const calculateTotalMealCalories = () => {
    const totalCalories = filteredMeals.reduce((acc, meal) => {
      return acc + (meal.calories || 0); // Ajoute les calories du repas à l'accumulateur
    }, 0);
    return totalCalories;
  };

  // Calcul du total des calories de tous les repas
  const totalMealCalories = calculateTotalMealCalories();

  useEffect(() => {
    // Calculer la différence entre les calories quotidiennes et les calories consommées
    const calculCalories = caloriesPerDay - totalMealCalories;
    // Calculer les calories restantes pour atteindre la limite quotidienne
    const totalCaloriesConsumed = caloriesPerDay - calculCalories;
    // Mettre à jour l'état des calories restantes
    setTotalCaloriesConsumed(totalCaloriesConsumed);
  }, [caloriesPerDay, totalMealCalories]);

  ///////////////////////////////////////////////////////////////////////////////////// SPORT ///////////////////////////////////////////////////////////////////// /////////////////////////////////////

  const handleSportChange = (event) => {
    setSelectedSport(event.target.value);

    if (duration && event.target.value) {
      calculateAndSetResult(event.target.value, duration);
    }
  };

  const handleDurationChange = (event) => {
    const durationValue = event.target.value;
    setDuration(durationValue);

    // durée vide mettre à jour les calories brûlées à 0
    if (!durationValue || durationValue === "") {
      setCalculatedResult(0);
      return;
    }

    // durée et le sport sélectionnés, calculer et mettre à jour les calories brûlées
    if (selectedSport && durationValue) {
      calculateAndSetResult(selectedSport, durationValue);
    }
  };

  const calculateResult = (weight, met, duration) => {
    return weight * (duration / 60) * met;
  };

  const calculateAndSetResult = (sport, duration) => {
    const userId = auth.getDecodedToken().sub;

    axios
      .get(`https://caloriesc.com/api/users/${userId}`)
      .then((response) => {
        const weight = response.data.user.weight;

        axios
          .get(`https://caloriesc.com/api/sports/${sport}`)
          .then((sportResponse) => {
            const met = sportResponse.data.met;

            // Calculer le résultat en utilisant la fonction calculateResult
            const result = calculateResult(weight, met, duration);
            // console.log(result);
            setCalculatedResult(result.toFixed(0));
          })
          .catch((error) => {
            console.error(
              "Erreur lors de la récupération du MET du sport :",
              error
            );
          });
      })
      .catch((error) => {});
  };

  ///////////////////////////////// Charge de l'activité enregistré pour l'afficher sur la page meme après recharge de la page ////////////////////////////////////////////////////
  useEffect(() => {
    const fetchUserActivity = async () => {
      try {
        const userId = auth.getDecodedToken().sub;
        const config = {
          headers: { Authorization: `Bearer ${auth.getToken()}` },
        };
        const response = await axios.get(
          `https://caloriesc.com/api/useractivity?user_id=${userId}`,
          config
        );

        setUserActivity(response.data); // Met à jour les activités utilisateur
        setRefreshSport(false); // Réinitialiser refreshSport une fois l'API appelée
      } catch (error) {
        console.error("Erreur lors de la récupération des activités :", error);
      }
    };

    fetchUserActivity();
  }, [sports]);

  ////////////////////////////////// Filtre des dates pour afficher les activitées selon les dates ////////////////////////////////////
  useEffect(() => {
    // Filtrer les activités d'utilisateur en fonction de la date sélectionnée
    const filtered = userActivity.filter((activity) => {
      const activityDate = new Date(activity.userActivityDate);
      return activityDate.toDateString() === selectedDate.toDateString();
    });
    setFilteredUserActivity(filtered);
  }, [userActivity, selectedDate]);
  /////////////////////////////////// SUPPRESSION DE L'ACTIVITE /////////////////////////////////////////////
  const handleDeleteActivity = async (activityId) => {
    try {
      await axios.delete(
        `https://caloriesc.com/api/useractivity/${activityId}`
      );
      // Supprime l'activité de l'utilisateur
      setUserActivity(
        userActivity.filter((activity) => activity.id !== activityId)
      );

      setRefreshSport(false);
    } catch (error) {}
  };
  ///////////////////////////////////////////////////////////////////////////////////

  const handleSubmit = (event) => {
    event.preventDefault();
    const userId = auth.getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${userId}`,
      },
    };
    axios
      .post(
        "https://caloriesc.com/api/useractivity",
        {
          user_id: userId,
          sport_id: selectedSport,
          duration: duration,
          caloriesburned: calculatedResult,
          userActivityDate: selectedDate,
        },
        config
      )
      .then((response) => {
        console.log("Activité physique enregistrée avec succès :");

        // console.log(response.data);
        setUserActivity([
          ...userActivity,
          {
            ...response.data,
            duration: duration,
            caloriesburned: calculatedResult,
            sport: selectedSport,
            userActivityDate: selectedDate,
          },
        ]);

        setSelectedSport("");
        setDuration("");
        setRefreshSport(true);

        axios.get("https://caloriesc.com/api/sports").then((response) => {
          setSports(response.data);
        });
      })
      .catch((error) => {
        console.error(
          "Erreur lors de l'enregistrement de l'activité physique :",
          error
        );
      });
  };
  useEffect(() => {
    // Récupération des sports depuis l'API
    axios
      .get("https://caloriesc.com/api/sports")
      .then((response) => {
        setSports(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des sports :", error);
      });
    setRefreshSport(false);
  }, []);

  ////////////////// Calcul Total Calories Activity /////////////////////////////////////////////////////

  useEffect(() => {
    // Calculer le total des calories brûlées
    const total = filteredUserActivity.reduce((acc, activity) => {
      // Convertir les calories brûlées en nombre avant de les additionner
      const calories = parseFloat(activity.caloriesburned);
      return acc + calories;
    }, 0);

    // Mettre à jour l'état du total des calories brûlées
    setTotalCaloriesBurned(total);

    const totalCaloriesRemaining =
      caloriesPerDay - totalCaloriesConsumed + total;

    setTotalCaloriesRemaining(totalCaloriesRemaining);
  }, [filteredUserActivity, totalCaloriesConsumed, caloriesPerDay]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="containerHome">
      <Header />
      <Navigation />
      <Calendar onDateChange={handleDateChange} />
      <div className="caloriesHome">
        <p className="totalCaloriesConsumed">
          {Math.floor(totalCaloriesConsumed)} Calories consommées{" "}
        </p>
        <p className="caloriesPerDay">{Math.floor(caloriesPerDay)} Calories</p>
        <p className="totalCaloriesRemaining">
          {Math.floor(totalCaloriesRemaining)} Calories restantes
        </p>
      </div>
      <Card.Title className="titleMeals">Les Repas</Card.Title>

      <div className="meals">
        <div className="breakfast">
          <Link to="/addMeal/breakfast">
            <h3>Petit déjeuner</h3>
          </Link>
          <TraitOrange width="180px" height="2px" margin="10px auto" />
          {breakfastMeals.map((meal) => (
            <div key={meal.id} className="mealBreakfast">
              <p>{meal.food ? meal.food.nameFoods : "N/A"}</p>
              <p>{meal.quantity} gr</p>
              <p>{meal.calories} calories</p>

              <Link to={`/editMeal/${meal.id}`}>
                <FaEdit className="editIconBreakfast" />
              </Link>
              <FaTrash
                className="deleteIconBreakfast"
                onClick={() => handleDeleteMeal(meal.id)}
              />
            </div>
          ))}
        </div>

        <div className="lunch">
          <Link to="/addMeal/lunch">
            <h3>Déjeuner</h3>
          </Link>
          <TraitOrange width="120px" height="2px" margin="10px auto" />
          {lunchMeals.map((meal) => (
            <div key={meal.id} className="mealLunch">
              <p>{meal.food ? meal.food.nameFoods : "N/A"}</p>
              <p>{meal.quantity} gr</p>
              <p>{meal.calories} calories</p>
              <Link to={`/editMeal/${meal.id}`}>
                <FaEdit className="editIconLunch" />
              </Link>
              <FaTrash
                className="deleteIconLunch"
                onClick={() => handleDeleteMeal(meal.id)}
              />
            </div>
          ))}
        </div>
        <div className="snack">
          <Link to="/addMeal/snack">
            <h3>Encas</h3>
          </Link>
          <TraitOrange width="70px" height="2px" margin="10px auto" />
          {snackMeals.map((meal) => (
            <div key={meal.id} className="mealSnack">
              <p>{meal.food ? meal.food.nameFoods : "N/A"}</p>
              <p>{meal.quantity} gr</p>
              <p>{meal.calories} calories</p>
              <Link to={`/editMeal/${meal.id}`}>
                <FaEdit className="editIconSnack" />
              </Link>
              <FaTrash
                className="deleteIconSnack"
                onClick={() => handleDeleteMeal(meal.id)}
              />
            </div>
          ))}
        </div>
        <div className="dinner">
          <Link to="/addMeal/dinner">
            <h3>Dîner</h3>
          </Link>
          <TraitOrange width="70px" height="2px" margin="10px auto" />
          {dinnerMeals.map((meal) => (
            <div key={meal.id} className="mealDinner">
              <p>{meal.food ? meal.food.nameFoods : "N/A"}</p>
              <p>{meal.quantity} gr</p>
              <p>{meal.calories} calories</p>
              <Link to={`/editMeal/${meal.id}`}>
                <FaEdit className="editIconDinner" />
              </Link>
              <FaTrash
                className="deleteIconDinner"
                onClick={() => handleDeleteMeal(meal.id)}
              />
            </div>
          ))}
        </div>
      </div>
      <Card.Title className="titleActivity">Activité Physique</Card.Title>
      <form onSubmit={handleSubmit} className="userActivity">
        <div className="sport">
          <label htmlFor="sportSelect">Sport :</label>
          <select
            id="sportSelect"
            className="selectSports"
            value={selectedSport}
            onChange={handleSportChange}
          >
            <option value="">Sélectionnez un sport</option>

            {sports.map((sport) => (
              <option key={sport.id} value={sport.id}>
                {sport.nameSports}
              </option>
            ))}
          </select>
        </div>
        <div className="duration">
          <label htmlFor="durationInput">Durée (en minutes) :</label>
          <input
            type="number"
            id="durationInput"
            className="inputDuration"
            value={duration}
            onChange={handleDurationChange}
          />
        </div>
        <button type="submit" className="btnValidate">
          Valider
        </button>
        <div className="totalUserActivity">
          {filteredUserActivity.map((activity, index) => {
            // Convertir activity.sport_id en nombre
            // console.log(activity);
            const sportId = parseInt(activity.sport_id, 10);
            // console.log(sportId);
            // Recherchez le sport dans le tableau sports en comparant les id
            const sport = sports.find((sport) => sport.id === sportId);
            // console.log(sport);
            return (
              <div className="sportActivity" key={index}>
                <p>{sport ? sport.nameSports : "Sport inconnu"}</p>
                <p>{activity.duration} minutes</p>
                <p>{Math.floor(activity.caloriesburned)} Calories brûlées</p>

                {/* <ButtonEdit go={`/editUserActivity/${activity.id}`} /> */}
                <Link to={`/editUserActivity/${activity.id}`}>
                  <FaEdit className="editIconActivity" />
                </Link>

                <FaTrash
                  className="deleteIconActivity"
                  onClick={() => handleDeleteActivity(activity.id)}
                />
              </div>
            );
          })}
          <div className="totalCaloriesBurned">
            <p>
              <strong>Total des calories brûlées :</strong>{" "}
              <span>{Math.floor(totalCaloriesBurned)} Calories</span>
            </p>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
};
export default Home;
