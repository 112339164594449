import axios from "axios";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Controller, useForm } from "react-hook-form";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../style/auth.scss";
import auth from "../../services/token";
import TraitOrange from "../TraitOrange";
import HeaderAuth from "./HeaderAuth";
function LoginForm() {
  document.title = "Connexion au site";

  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { email: "", password: "" } });

  const navigate = useNavigate();

  const login = async (data) => {
    try {
      let formData = new FormData();
      formData.append("email", data.email);
      formData.append("password", data.password);
      let res = await axios.post("https://caloriesc.com/api/login/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (res.status === 200) {
        const token = res.data.data.auth.token;
        auth.saveToken(token);

        navigate("/home", { replace: true });
        window.location.reload();
      }
    } catch (err) {
      setMessage("Email ou mot de passe incorrect", err);
    }
  };

  useEffect(() => {
    // Récupére le message depuis localStorage
    const successMessage = localStorage.getItem("deleteSuccessMessage");
    const errorMessage = localStorage.getItem("deleteErrorMessage");

    if (successMessage) {
      setMessage(successMessage);
      localStorage.removeItem("deleteSuccessMessage"); // Supprime le message après l'affichage
    } else if (errorMessage) {
      setMessage(errorMessage);
      localStorage.removeItem("deleteErrorMessage"); // Supprime le message après l'affichage
    }
  }, []);
  return (
    <div className="containerLogin">
      <HeaderAuth />

      <Card.Body>
        <Card.Title className="titleLogin">CONNECTION</Card.Title>
        <TraitOrange width="200px" height="2px" margin="14px auto 30px" />
        <Form.Group className="formLogin">
          <Form onSubmit={handleSubmit(login)}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: "Mail obligatoire" }}
                render={({ field }) => (
                  <>
                    <Form.Control type="email" placeholder="Email" {...field} />
                    {errors.email && (
                      <Form.Text className="text-danger">
                        {errors.email.message}
                      </Form.Text>
                    )}
                  </>
                )}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <InputGroup>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Mot de passe est obligatoire" }}
                  render={({ field }) => (
                    <>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Mot de passe"
                        {...field}
                      />
                      {errors.password && (
                        <Form.Text className="text-danger">
                          {errors.password.message}
                        </Form.Text>
                      )}
                    </>
                  )}
                />
              </InputGroup>
            </Form.Group>

            <Button type="submit" className="w-100 buttonLogin btnLogin">
              Se connecter
            </Button>
            <div>
              <Link to="/register" className="createAccountLogin">
                Créer un compte
              </Link>
            </div>
          </Form>
        </Form.Group>
        {message && <div className="alert alert-info">{message}</div>}
      </Card.Body>
    </div>
  );
}

export default LoginForm;
