import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Navigation from "../../components/Navigation";
import auth from "../../services/token";
const AddMeal = () => {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState({});
  const [quantity, setQuantity] = useState("");
  const [food_id, setFoodId] = useState("");
  const [foods, setFoods] = useState([]);
  const [calories, setCalories] = useState(0);
  const { typeMeal } = useParams();
  const [error, setError] = useState(null);

  const handleChange = (event) => {
    setFoodId(event.target.value);
  };

  useEffect(() => {
    if (quantity && food_id) {
      calculateCalories();
    } else {
      setCalories(0); // Réinitialiser les calories à 0 si quantity ou food_id est vide
    }
  }, [quantity, food_id]);

  const getFoods = async () => {
    try {
      const response = await axios.get("https://caloriesc.com/api/foods");

      setFoods(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des aliments", error);
    }
  };
  useEffect(() => {
    getFoods();
  }, []);

  const calculateCalories = async () => {
    if (!quantity || isNaN(quantity) || !food_id) {
      setCalories(0);
      return;
    }

    try {
      const response = await axios.get(
        `https://caloriesc.com/api/foods/${food_id}`
      );
      const selectedFood = response.data;

      const fats = selectedFood.fats || 0;
      const carbohydrates = selectedFood.carbohydrates || 0;
      const proteins = selectedFood.proteins || 0;
      const calculCalorieFats = fats * 9;
      const calculCalorieCarbohydrates = carbohydrates * 4;
      const calculCalorieProteins = proteins * 4;
      const totalCalorie =
        calculCalorieFats + calculCalorieCarbohydrates + calculCalorieProteins;
      let calculatedCalories = (totalCalorie * parseInt(quantity)) / 100;
      calculatedCalories = Math.round(calculatedCalories);

      setCalories(calculatedCalories);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des détails de l'aliment",
        error
      );
      setCalories(0); // Réinitialiser à 0 en cas d'erreur
    }
  };

  const addMeals = async (e) => {
    e.preventDefault();

    // Appeler calculateCalories pour mettre à jour les calories
    await calculateCalories();

    const formData = new FormData();
    formData.append("quantity", quantity);
    formData.append("calories", calories);
    formData.append("food_id", food_id);
    formData.append("typeMeals", typeMeal);

    const userId = auth.getDecodedToken().sub;

    formData.append("user_id", userId);

    try {
      const token = auth.getToken(); //recupere le token de l'utilisateur connecté

      const config = {
        headers: {
          Authorization: `Bearer ${token}`, // Inclure le token JWT dans l'en-tête Authorization
        },
      };

      // Creation d'un nouvel repas
      await axios.post(`https://caloriesc.com/api/meals`, formData, config);
      // création validée on retourne à la page home
      navigate("/home");
      window.location.reload();
    } catch (error) {
      console.log(error.response);
      if (error.response.status === 422) {
        setValidationError(error.response.data.errors);
      }
    }

    const saveMealType = async () => {
      try {
        // Enregistrer le type de repas dans la base de données
        await axios.post(`https://caloriesc.com/api/meals`);

        // Réinitialiser l'erreur si l'opération réussit
        setError(null);
      } catch (error) {
        setError("Erreur lors de l'enregistrement du repas");
      }
    };

    saveMealType();
  };

  return (
    <div className="generalContainerAddMeal">
      <Header />
      <Navigation />
      <div className="containerAddMeal">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-6">
            <div className="card mt-5 ">
              <div className="card-body ">
                <h4 className="titleAddMeal">Ajouter un aliment </h4>
                <hr />
                <div className="form-wrapper">
                  {Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {Object.entries(validationError).map(
                              ([key, value]) => (
                                <li key={key}>{value}</li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  <Form onSubmit={addMeals}>
                    <Row>
                      <Col>
                        <Form.Group controlId="quantity">
                          <Form.Label>Quantité (en grammes)</Form.Label>
                          <Form.Control
                            type="text"
                            value={quantity}
                            onChange={(event) => {
                              const value = event.target.value;
                              if (value === "" || !isNaN(value)) {
                                setQuantity(value);
                                if (value) {
                                  calculateCalories(); // Calculer les calories si la quantité est non vide
                                } else {
                                  setCalories(0); // Réinitialiser les calories si la quantité est vide
                                }
                              }
                            }}
                            onBlur={calculateCalories}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="foods">
                          <Form.Label>Aliment</Form.Label>
                          <Form.Control
                            as="select"
                            value={food_id}
                            onChange={handleChange}
                          >
                            <option value="">Sélectionnez un aliment</option>
                            {foods.map((food) => (
                              <option key={food.id} value={food.id}>
                                {food.nameFoods}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="calories">
                          <Form.Label>Calories</Form.Label>
                          <Form.Control type="text" value={calories} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>
                    {error && <div style={{ color: "red" }}>{error}</div>}
                    <Button
                      className="btnAddMeal"
                      size="lg"
                      block="block"
                      type="submit"
                    >
                      Créer
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AddMeal;
